import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrls';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const authenticationReducer = createSlice({
    name: 'adminAuthentication',
    initialState: {},
    reducers: {

        resetChangeStatus(state, action) {

            return { ...state, loginSuccess: undefined }

        },

        logout(state, action) {

            localStorage.clear("user");

            // window.location.reload();        

            return {};

        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(adminLogin.pending, () => {
                return { loading: true, loginSuccess: undefined }
            })
            .addCase(adminLogin.fulfilled, (state, action) => {
                localStorage.setItem("user", JSON.stringify(action?.payload?.adminUser));
                localStorage.setItem('accessToken', action?.payload?.accessToken);
                // localStorage.setItem('refreshToken', action.payload.refreshToken);

                return { loading: false, user: action.payload, loginSuccess: true }
            })
            .addCase(adminLogin.rejected, (state, action) => {
                return {
                    loading: false,
                    loginSuccess: false,
                    error: action.payload
                }
            });
    },
});

export default authenticationReducer.reducer;
export const { resetChangeStatus, logout } = authenticationReducer.actions;

// Thunks
export const adminLogin = createAsyncThunk('adminLogin/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.login, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

