import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import CustomLoading from "../../../components/custom_loading";
import { InputText } from "primereact/inputtext";
const DashboardScreen = () => {
    const [lineData, setLineData] = useState();
    const [slineData, setSlineData] = useState();
    const [barData, setBarData] = useState();
    const [tlineOptions, setSlineOptions] = useState(null);
    const [tlineData, setTlineData] = useState();
    const [lineOptions, setLineOptions] = useState(null);
    const [slineOptions, setTlineOptions] = useState(null);
    const [startDate, setStartDate] = useState(new Date().getFullYear().toString() + "-" + new Date().getMonth().toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));
    const [endDate, setEndDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const applyLightThemeS = () => {
        const slineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setSlineOptions(slineOptions);
    };

    const applyLightThemeT = () => {
        setTlineOptions(tlineOptions);
    };
    const { data = {}, loading = false } = {};

    useEffect(() => {
        if (data !== undefined && loading === false) {
            let driverMonths = data?.routeStat?.lineChartData.map((item) => item._id);
            let driverCounts = data?.routeStat?.lineChartData.map((item) => item.count);

            let passengerCounts = data?.scheduleStat?.lineChartData.map((item) => item.count);
            let chartData = {
                labels: driverMonths,
                datasets: [
                    {
                        label: "Male",
                        data: driverCounts,
                        fill: false,
                        backgroundColor: "#2f4860",
                        borderColor: "#2f4860",
                        tension: 0.4,
                    },
                    {
                        label: "Female",
                        data: passengerCounts,
                        fill: false,
                        backgroundColor: "#00bb7e",
                        borderColor: "#00bb7e",
                        tension: 0.4,
                    },
                ],
            };
            setLineData(chartData);
            let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let maleMonths = data?.userStat?.genderBarChart.filter((item) => item._id.gender === "male").map((item) => `${monthNames[item._id.month - 1]} ${item.year}`);
            let maleCounts = data?.userStat?.genderBarChart.filter((item) => item._id.gender === "male").map((item) => item.count);
            let femaleCounts = data?.userStat?.genderBarChart.filter((item) => item._id.gender === "female").map((item) => item.count);

            const barChartData = {
                labels: maleMonths,
                datasets: [
                    {
                        label: "Males",
                        backgroundColor: "#2f4860",
                        data: maleCounts,
                    },
                    {
                        label: "Females",
                        backgroundColor: "#00bb7e",
                        data: femaleCounts,
                    },
                ],
            };
            setBarData(barChartData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data !== undefined && loading === false) {
            let driverMonths = data?.routeStat?.lineChartData.map((item) => item._id);
            let driverCounts = data?.routeStat?.lineChartData.map((item) => item.count);

            let passengerCounts = data?.scheduleStat?.lineChartData.map((item) => item.count);
            let chartData = {
                labels: driverMonths,
                datasets: [
                    {
                        label: "Received",
                        data: driverCounts,
                        fill: false,
                        backgroundColor: "#4A78B8",
                        borderColor: "#4A78B8",
                        tension: 0.4,
                    },
                    {
                        label: "Payable",
                        data: passengerCounts,
                        fill: false,
                        backgroundColor: "#9BA8B9",
                        borderColor: "#9BA8B9",
                        tension: 0.4,
                    },
                ],
            };
            setSlineData(chartData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data !== undefined && loading === false) {
            let driverMonths = data?.routeStat?.lineChartData.map((item) => item._id);
            let driverCounts = data?.routeStat?.lineChartData.map((item) => item.count);

            let passengerCounts = data?.scheduleStat?.lineChartData.map((item) => item.count);
            let chartData = {
                labels: driverMonths,
                datasets: [
                    {
                        label: "Received",
                        data: driverCounts,
                        fill: false,
                        backgroundColor: "#CD5942",
                        borderColor: "#CD5942",
                        tension: 0.4,
                    },
                    {
                        label: "Paid",
                        data: passengerCounts,
                        fill: false,
                        backgroundColor: "#B29994",
                        borderColor: "#B29994",
                        tension: 0.4,
                    },
                ],
            };
            setTlineData(chartData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(() => {
        applyLightTheme();
    }, []);
    useEffect(() => {
        applyLightThemeS();
    }, []);
    useEffect(() => {
        applyLightThemeT();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading === undefined || data === undefined ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-6">{/* <CustomPageHeader title={"Dashboard"} /> */}</div>
                        <div className="col-6">
                            <div className="both__date">
                                <InputText value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="date__input" />

                                <InputText value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="date__input" />
                            </div>
                        </div>

                        {data && (
                            <>
                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Total Users </span>
                                                <div className="text-900 font-medium text-xl">{data?.userStat?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-users text-blue-500 text-xl" />
                                            </div>
                                        </div>

                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male </span>
                                                <span className="text-green-500 font-medium">{data?.userStat?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female </span>
                                                <span className="text-green-500 font-medium">{data?.userStat?.female}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Blocked Users </span>
                                                <div className="text-900 font-medium text-xl">{data?.userStat?.blocked}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-user-minus  text-orange-500 text-xl" />
                                            </div>
                                        </div>
                                        <span className="text-500">Total </span>
                                        <span className="text-green-500 font-medium">{data?.userStat?.blocked}</span>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Number of Jobs </span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.cancelled?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-folder-open text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.female}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Total Received</span>
                                                <div className="text-900 font-medium text-xl">${data?.scheduleStat?.countByStatus?.active?.total}</div>
                                            </div>

                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-money-bill text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.female}</span>
                                            </div>
                                        </div>
                                        {/* <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Total </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Total </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.todayFemale}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Total Payable</span>
                                                <div className="text-900 font-medium text-xl">${data?.scheduleStat?.countByStatus?.pending?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-money-bill text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.female}</span>
                                            </div>
                                        </div>
                                        {/* <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Total </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Total </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.todayFemale}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="col-12 lg:col-6 xl:col-4">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Z Verified Accounts</span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.completed?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-check text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female </span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.female}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-12 lg:col-6">
                            {lineData !== undefined && (
                                <div className="card">
                                    <h5>Users</h5>
                                    <Chart type="line" data={lineData} options={lineOptions} />
                                </div>
                            )}
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="card">
                                <h5>Blocked Users</h5>
                                <Chart type="bar" data={barData} options={null} />
                            </div>
                        </div>
                        <div className="col-12 lg:col-6">
                            {slineData !== undefined && (
                                <div className="card">
                                    <h5>Received/ Payable</h5>
                                    <Chart type="line" data={slineData} options={slineOptions} />
                                </div>
                            )}
                        </div>
                        <div className="col-12 lg:col-6">
                            {tlineData !== undefined && (
                                <div className="card">
                                    <h5>Received/ Paid</h5>

                                    <Chart type="line" data={tlineData} options={tlineOptions} />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(DashboardScreen, comparisonFn);
