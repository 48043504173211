import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomLoading from "../../../components/custom_loading";
import CustomInputField from "../../../components/custom_input_filed";

export default function AddEditAdminUserDialog({ onHide, editUser }) {
    const getUserReducer = useSelector((state) => state.getUser);
    const { roles, loading, addSuccess, addLoading, editSuccess, editLoading } = getUserReducer;

    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];

    const loadEditData = () => {
        // formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("firstName", editUser["firstName"]);
        formik.setFieldValue("lastName", editUser["lastName"]);
        formik.setFieldValue("email", editUser["email"]);
        formik.setFieldValue("mobile", editUser["mobile"]);
        formik.setFieldValue("status", editUser["status"]);
        // console.log(editUser.status)
        formik.setFieldValue("role", editUser["role"]?._id);
    };

    useEffect(() => {
        if (roles === undefined || roles.length === 0) {
        }
        if (editUser != null) {
            loadEditData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        lastName: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        password: editUser == null ? Yup.string().max(50, "Maximum length 50 allowed").required("This field is required.").min(8, "Minimum length should be 8") : Yup.string().min(8, "Minimum length should be 8"),

        email: Yup.string()
            .email("Invalid email address format")
            .required("This field is required.")
            .matches(/@rahper\.com$/, "Input must end with @rahper.com"),
        mobile: Yup.string()
            .required("This field is required.")
            .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
            .max(11, "Maximum length 11 allowed")
            .nullable(),
        role: Yup.string().required("Role is required"),
    });

    useEffect(() => {
        if (!editUser && addSuccess) onHide();
        if (editUser && editSuccess) onHide();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSuccess, editSuccess]);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            status: "",
        },

        onSubmit: async (data) => {
            // data.userName = data.userName.toLowerCase();

        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {loading === true ? (
                    <CustomLoading />
                ) : (
                    <div className="grid">
                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        {/* keyfilter={/^[a-z0-9_-]+$/}*/}

                                        <CustomInputField label="First Name" iden={"firstName"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        {/* keyfilter={/^[a-z0-9_-]+$/}*/}
                                        <CustomInputField iden={"lastName"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"password"} formik={formik} type="text" placeholder="Please enter a password" feedback={false} />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"email"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"mobile"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"role"} formik={formik} type="dropdown" optionValue="_id" optionLabel="role" options={roles} />
                                    </div>
                                </div>
                                {editUser != null && (
                                    <div className="col-12 md:col-6">
                                        <div className="field">
                                            <CustomInputField iden={"status"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                                        </div>
                                    </div>
                                )}

                                <div className="col-12 md:col-12">
                                    <div className="button_class-modal">
                                        <Button loading={editUser == null ? addLoading : editLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
}
