import React, { useState, useEffect, useRef } from "react";
import "./App.scss";

import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { ToastContainer } from "react-toastify";
import { AppConfig } from "./AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import dashboard from "./app/features/screens/dashboard/pages/DashboardScreen";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "react-toastify/dist/ReactToastify.css";

import AdminUsersScreen from "./app/features/screens/users/pages/AdminUserScreen";
import roles from "./app/features/screens/Roles/pages/RoleScreen";
import enrolment from "./app/features/screens/enrolment/pages/EnrolmentScreen";
import LedgerScreen from "./app/features/screens/accounts/pages/LedgerScreen";
// import { useContext } from "react";
// import { SocketContext } from "./Context";

const App = () => {
    const [currentModuleLabel, setCurrentModuleLabel] = useState("Dashboard"); // Initialize with "Dashboard"

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    // let { socket } = useContext(SocketContext);

    const menu = [
        {
            items: [
                { label: "Dashboard", icon: "pi pi-briefcase", to: "/" },
                { label: "Freelancer Ledger", icon: "pi pi-briefcase", to: "/ladger" },
                { label: "Admin User", icon: "pi pi-briefcase", to: "/user" },
                { label: "Roles", icon: "pi pi-briefcase", to: "/roles" },
                { label: "Enrolment", icon: "pi pi-briefcase", to: "/enrolment" },
            ],
        },
    ];

    // const socket = io(appUrl.baseUrl+'?name=Waseem 3');
    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };
    // const onMenuItemClick = (event) => {
    //     if (!event.item.items) {
    //         setOverlayMenuActive(false);
    //         setMobileMenuActive(false);
    //     }
    // };
    const handleMenuItemClick = (moduleName) => {
        setCurrentModuleLabel(moduleName); // Update the current module label when a menu item is clicked
        setOverlayMenuActive(false);
        setMobileMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth >= 992;
    };
    // function capitalizeFirstLetter(str) {
    //     if (str?.length === 0) {
    //         return str; // Return empty string if input is empty
    //     } else {
    //         return str?.charAt(0).toUpperCase() + str?.slice(1);
    //     }
    // }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <>
            <ToastContainer />

            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar
                    onToggleMenuClick={onToggleMenuClick}
                    layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive}
                    onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                    onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    currentModuleLabel={currentModuleLabel} // Pass the current module label as a prop
                />
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={handleMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                <div className="layout-main-container">
                    <div className="layout-main">
                        <Switch>
                            <Route exact path="/ladger" component={LedgerScreen} />
                            <Route exact path="/user" component={AdminUsersScreen} />
                            <Route exact path="/roles" component={roles} />
                            <Route exact path="/enrolment" component={enrolment} />
                            {/* <Route exact path="/test" component={} /> */}
                            <Route exact path="/" component={dashboard} />

                            {/* <Route path="" /> */}
                        </Switch>
                    </div>
                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </div>
        </>
    );
};

export default App;
