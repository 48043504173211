import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'; // Import createSlice and createAsyncThunk
import appUrl from '../../app/features/constants/appUrls';

// Define the thunk function before using it


const adminUserReducer = createSlice({
    name: 'adminUserSlice',
    initialState: {},
    reducers: {
        resetChangeStatus(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminUserList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getAdminUserList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getAdminUserList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export const getAdminUserList = createAsyncThunk('adminUser/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getAdminUserList);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

export default adminUserReducer.reducer;
export const { resetChangeStatus } = adminUserReducer.actions;
