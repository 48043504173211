import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Paginator } from "primereact/paginator";
import AddEditRoleDialog from "../components/add_edit_role_dialog";

import { confirmDialog } from 'primereact/confirmdialog';

const RoleScreen = () => {


    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const users = []
    const count = []
    const loading = false
    const [first, setFirst] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [editUser, setEditUser] = useState(null);
    const [userRowData] = useState("");





    const onClick = () => {

        setShowAddUserDialog(true);
    };

    const onHide = () => {
        setShowAddUserDialog(false);
    }



    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        console.log(pageNumber)
    };


    const handleEditClick = async () => {
        console.log('Edit button clicked');
        setEditUser(true);

    };
    const confirmDeleteAd = () => {
        confirmDialog({
            message: 'Do you want to delete this User?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            // accept,

        });
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="d-flex" style={{ width: "100px" }}>

                <Button
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button-edit"
                    onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-edit"
                    // onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-delete"
                    onClick={() => {
                        // deleteId = rowData.id;
                        console.log('datadelete', { rowData })
                        confirmDeleteAd();

                    }}
                />
            </div>
        );
    };

    return (
        <> <Dialog
            header={editUser ? "Add User" : "Update User"}
            visible={showAddUserDialog}
            onHide={() => onHide("showAddUserDialog")}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            style={{ width: "70vw" }} className="Modal_class">
            <AddEditRoleDialog
                editUser={editUser}
                userRowData={userRowData}
                showAddUserDialog={showAddUserDialog}
                onHide={() => onHide("showAddUserDialog")} />
        </Dialog>



            <div className="card">

                <div className="grid pb-3">
                    <div className="col-12 md:col-9">

                    </div>
                    <div className="mb-9 text-right col-12 md:col-3">
                        <Button
                            label="Add new"
                            className="p-button-success p-button-rounded p-button-sm custom___width"
                            onClick={() => {
                                setEditUser(null);
                                onClick("showAddUserDialog");
                            }}
                        />
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={users} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]} emptyMessage="No record found.">

                    <Column style={{ minWidth: "" }} header="Title" body={(rowData) => "Admin"} />
                    <Column style={{ minWidth: "" }} header="Id" body={(rowData) => "Admin"} />

                    {/* <Column body={statusBody} header="Status" /> */}
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div >
        </>
    )
}

export default RoleScreen