import React, { createContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import appUrl from "./app/features/constants/appUrls";

const SocketContext = createContext();

const ContextProvider = ({ children }) => {
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const socket = io(appUrl.socketBaseUrl);

    useEffect(() => {
        // Listen for the "connect" and "disconnect" events to track socket connection status.
        socket.on("connect", () => {
            setIsSocketConnected(true);
        });

        socket.on("disconnect", () => {
            setIsSocketConnected(false);
        });

        // Clean up the event listeners when the component unmounts.
        return () => {
            socket.off("connect");
            socket.off("disconnect");
        };
    }, [socket]);

    return (
        <SocketContext.Provider
            value={{
                socket,
                isSocketConnected,
            }}
        >
            {children}
        </SocketContext.Provider>
    );
};

export { ContextProvider, SocketContext };
