import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useEffect } from 'react';


export const AppTopbar = (props) => {
    const [currentModuleLabel, setCurrentModuleLabel] = useState("Dashboard"); // Initial value
    const [currentURL] = useState(window.location.hash)

    useEffect(() => {
        const url = window.location.hash
        if (url !== currentURL) {
            if (url === '#/')
                setCurrentModuleLabel('Dashboard')
            else if (url === '#/ladger') {
                setCurrentModuleLabel('Freelancer Ledger')

            }
            else if (url === '#/user') {
                setCurrentModuleLabel('Admin User')

            }
            else {
                setCurrentModuleLabel('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const accept = () => {
    }

    const reject = () => {
    }

    const signoutConfirm = () => {
        confirmDialog({
            message: 'Do you want to logout?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success p-button-rounded p-button-sm custom___width',
            accept,
            reject
        });
    };

    return (
        <div className="layout-topbar">

            <ConfirmDialog />

            <Link to="/" className="layout-topbar-logo">
                <img src='app/logo.png' alt="logo" />
                {/* <span>Rahper</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>
            <h4>{currentModuleLabel}</h4>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>

                <li>
                    <button className="p-link layout-topbar-button" onClick={() => {
                        signoutConfirm();
                    }}>
                        <i className="pi pi-power-off" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
