import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import CustomLoading from "../../../components/custom_loading";
import { DataTable } from "primereact/datatable";
export default function LadgerScreen() {
    const list = []
    const loading = false

    const [expandedRows, setExpandedRows] = useState(null);


    const dt = useRef(null);









    const actionTemplate = (rowData) => {
        return (
            <div className="d-flex" style={{ width: "100px" }}>

                <Button
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button-edit"
                    // onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-edit"
                    // onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                {/* <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-delete"
                    onClick={() => {
                        deleteId = rowData.id;
                        console.log('datadelete', { rowData })
                        confirmDeleteAd();

                    }}
                /> */}
            </div>
        );
    };


    const rowExpansionTemplate = (rowData) => {
        return (
            <>
                <h5>Jobs Completed</h5>
                <DataTable showGridlines={true} value={rowData.history}>
                    <Column
                        body={(rowData, index) => {
                            return (index.rowIndex + 1)
                        }} // This adds the serial number
                        header="Sr. No."

                    />
                    <Column field="passenger.mobile" header="Job Id " />
                    <Column field="" header="Job Title " body={(rowData) => "Admin"} />
                    <Column field="" header="Client Id " body={(rowData) => "000    "} />
                    <Column
                        // field="schedule.endPoint.placeName"
                        header="Start Date" body={(rowData) => "12/01/2022"} />
                    <Column
                        // field="schedule.endPoint.placeName"
                        header="End Date" body={(rowData) => "12/01/2022"} />
                    <Column
                        // body={createdAtTemplate}
                        header="Payment Received" style={{ minWidth: "12rem" }} body={(rowData) => "550"} />
                </DataTable>
            </>
        );
    };
    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };
    useEffect(() => {

    }, []);


    return (
        <>

            {loading === true || list === undefined ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <div className="grid pb-3">

                    </div>
                    <h5 className="mt-0">Freelancer</h5>
                    <DataTable
                        filter
                        expandedRows={expandedRows}
                        rows={50}
                        paginator
                        value={list}
                        responsiveLayout="scroll"
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                        rowExpansionTemplate={rowExpansionTemplate}
                        ref={dt}
                        globalFilterFields={["driver.firstName", "driver.lastName", "driver.cnic", "driver.email", "driver.zindigiWallet.zindigiWalletNumber"]}
                    >
                        <Column expander={allowExpansion} style={{ width: "3em" }} />
                        <Column
                            body={(rowData, index) => {
                                return (index.rowIndex + 1)
                            }} // This adds the serial number
                            header="Sr. No."
                            style={{ width: '5em', textAlign: 'center' }}
                        />
                        <Column field="driver.firstName" header="First Name" />
                        <Column field="driver.lastName" header="Last Name" />
                        <Column field="driver.mobile" header="Mobile" />
                        <Column field="driver.cnic" header="CNIC" />
                        <Column field="driver.email" header="Email" />
                        <Column field="" header="Upwork Id " body={(rowData) => "000000000"} />
                        <Column field="driver.zindigiWallet.zindigiWalletNumber" header="Zindigi Wallet No." body={(rowData) => "111111"} />
                        <Column field="" header="Cash Out " body={(rowData) => "300"} />
                        <Column field="total" header="Total Amount($)" style={{ textAlign: "center" }} />
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>

                </div>
            )}
        </>
    );
}
