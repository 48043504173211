import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import store from "./store";
import { Provider } from "react-redux";
import appUrl from "./app/features/constants/appUrls";
import { ContextProvider } from "./Context";

ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            <Provider store={store}>
                <ContextProvider socketUrl={appUrl.socketBaseUrl}>
                    <App />
                </ContextProvider>
            </Provider>
        </ScrollToTop>
    </HashRouter>,
    document.getElementById("root")
);
