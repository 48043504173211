import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { confirmDialog } from 'primereact/confirmdialog';
import { Paginator } from "primereact/paginator";
import moment from "moment";
import AddEditAdminUserDialog from "../components/AddEditAdminUserDialog";
import { getAdminUserList } from "../../../../../redux/slices/adminUserSlices";

export default function AdminUserScreen() {
    //redux
    const adminUserReducer = useSelector((state) => state.adminUserSlice);
    const { data, loading, count } = adminUserReducer;
    const dispatch = useDispatch();

    const [showAddUserDialog, setShowAddUserDialog] = useState(false);


    // const count = 0
    const [first, setFirst] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    //hooks
    const [editUser, setEditUser] = useState(null);
    const [userRowData,] = useState("");
    console.log(pageNumber)

    const onClick = (name) => {

        setShowAddUserDialog(true);
    };

    const onHide = (name) => {
        setShowAddUserDialog(false);
    }


    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);

    };
    //methods
    //table body

    const statusBody = (rowData) => {
        // return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
        return <div className={rowData.isActive ? "bg_green" : "bg_red"}>{rowData.isActive ? "Active" : "Inactive"}</div>;
    };


    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };


    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const updatedAtTemplate = (rowData) => {
        return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const handleEditClick = async (rowData) => {

    };
    const confirmDeleteAd = () => {
        confirmDialog({
            message: 'Do you want to delete this User?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            // accept,

        });
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="d-flex" style={{ width: "100px" }}>


                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-edit"
                    // onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                <Button
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button-edit"
                    onClick={() => handleEditClick(rowData)}
                    aria-label="Edit"
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-delete"
                    onClick={() => {
                        // deleteId = rowData.id;
                        console.log('datadelete', { rowData })
                        confirmDeleteAd();

                    }}
                />
                {/* <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-delete"
                    onClick={() => {
                        deleteId = rowData.id;
                        console.log('datadelete', { rowData })
                        confirmDeleteAd();

                    }}
                /> */}
            </div>
        );
    };
    useEffect(() => {
        dispatch(getAdminUserList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Dialog
                header={editUser ? "Add User" : "Update User"}
                visible={showAddUserDialog}
                onHide={() => onHide("showAddUserDialog")}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                style={{ width: "70vw" }} className="Modal_class">
                <AddEditAdminUserDialog
                    editUser={editUser}
                    userRowData={userRowData}
                    showAddUserDialog={showAddUserDialog}
                    onHide={() => onHide("showAddUserDialog")} />
            </Dialog>

            {/* <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog> */}

            <div className="card">

                <div className="grid pb-3">
                    <div className="col-12 md:col-9">

                    </div>
                    <div className="mb-9 text-right col-12 md:col-3">
                        <Button
                            label="Add new"
                            className="p-button-success p-button-rounded p-button-sm custom___width"
                            onClick={() => {
                                setEditUser(null);
                                onClick("showAddUserDialog");
                            }}
                        />
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={data} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]} emptyMessage="No record found.">
                    <Column
                        body={(rowData, index) => {
                            return (index.rowIndex + 1)
                        }} // This adds the serial number
                        header="Sr. No."

                    />
                    <Column style={{ maxWidth: "100%", overflowX: "auto" }} body={firstNameTemp} header="First Name" />
                    <Column style={{ maxWidth: "100%", overflowX: "auto" }} body={lastNameTemp} header="Last Name" />
                    {/* <Column style={{ minWidth: "" }} field="cnic" header="CNIC" /> */}
                    <Column style={{ minWidth: "" }} field="mobile" header="Mobile" />
                    {/* <Column style={{ minWidth: "" }} field="mobile" header="Role" /> */}
                    <Column style={{ minWidth: "" }} header="Role" body={(rowData) => rowData?.role?.title} />


                    {/* <Column style={{ maxWidth: "" }} body={genderTemp} header="Gender" /> */}
                    <Column style={{ minWidth: "" }} body={createdAtTemplate} header="Created At" />
                    <Column style={{ minWidth: "" }} body={updatedAtTemplate} header="Updated At" />
                    {/*<Column filter body={statusBodyDriver} header="Status" /> */}
                    <Column body={statusBody} header="Status" />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div >
        </>
    );
}
