
import { configureStore } from "@reduxjs/toolkit";

import adminAuthentication from "./redux/slices/adminAuthenticationSlice";
import adminUserSlice from "./redux/slices/adminUserSlices";

const user = localStorage.getItem("user");

const initialState = {
    loginUser: {
        user: user ? JSON.parse(user) : undefined,
    },
};

const store = configureStore({
    reducer: {
        adminAuthentication,
        adminUserSlice,

    },
    preloadedState: initialState,
});

export default store;
