import React, { } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation

import CustomInputField from "../../../components/custom_input_filed";
import CustomLoading from "../../../components/custom_loading";

const EnrolmentScreen = () => {

    const loading = false

    // Define the validation schema
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        lastName: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        country: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        email: Yup.string()
            .email("Invalid email address format")
            .required("This field is required.")
        ,
        phone: Yup.string()
            .required("This field is required.")
            .matches(/^[A-Za-z0-9 ]+$/, "Special Characters are not allowed")
            .max(11, "Maximum length 11 allowed")
            .nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            country: "",
            phone: "",
            email: "",
            upworkId: "",
            upworkRating: "",
            upworkProfileUrl: "",
        },
        // onSubmit function for adding/editing users
        onSubmit: (values) => {
            // You can dispatch the add or edit action here based on your requirements.
            // Example:
            // if (editUser === null) {
            //     dispatch(addAdminUser(values));
            // } else {
            //     dispatch(editAdminUser({ id: editUser.id, data: values }));
            // }
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {loading === true ? (
                    <CustomLoading />
                ) : (
                    <div className="grid">
                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-6">
                                    <div className="field">

                                        <CustomInputField label="First Name" iden={"firstName"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">

                                        <CustomInputField label="Last Name" iden={"lastName"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">

                                        <CustomInputField label="Upwork Id" iden={"upworkId"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        {/* keyfilter={/^[a-z0-9_-]+$/}*/}
                                        <CustomInputField label="Phone Number" iden={"phone"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField label="Email" iden={"email"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField label="Upwork Rating" iden={"upworkRating"} formik={formik} type="text" feedback={false} />
                                    </div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField label="Upwork Profile Url" iden={"upworkProfileUrl"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField label="Country" iden={"mobile"} formik={formik} type="text" />
                                    </div>
                                </div>





                                <div className="col-12 md:col-12">
                                    <div className="button_class-modal">
                                        <Button
                                            // loading={editUser == null ? addLoading : editLoading}
                                            type="submit"
                                            label="Add"
                                            // label={editUser === null ? "Add" : "Update"}
                                            className="p-button-success btn__default_modal p-button-rounded" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </>
    )
}

export default EnrolmentScreen